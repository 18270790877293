// Bootstrap
@import '~bootstrap/scss/bootstrap';

.app-index-spinner {
    margin: 60px auto;
}

$hover_color1: rgba(189,13,189,1);
$hover_color2: #f9bc1c;
$hover_color3: #78cdd8;

.site-item {
    border: 1px solid #ccc;
    margin: 3px;

    .helper {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
    img {
        max-width: 100%;
        vertical-align: middle;
    }

    &.hover-1:hover, &:hover {
        border: 1px solid #7c7c7c;
        -webkit-box-shadow: inset 0px 0px 65px -46px $hover_color1;
        -moz-box-shadow: inset 0px 0px 65px -46px $hover_color1;
        box-shadow: inset 0px 0px 65px -46px $hover_color1;
    }
    &.hover-2:hover {
        -webkit-box-shadow: inset 0px 0px 65px -46px $hover_color2;
        -moz-box-shadow: inset 0px 0px 65px -46px $hover_color2;
        box-shadow: inset 0px 0px 65px -46px $hover_color2;
    }
    &.hover-3:hover {
        -webkit-box-shadow: inset 0px 0px 65px -46px $hover_color3;
        -moz-box-shadow: inset 0px 0px 65px -46px $hover_color3;
        box-shadow: inset 0px 0px 65px -46px $hover_color3;
    }

    .ps-info {
        bottom: 5px;
        right: 5px;
    }
}

#first-line-wrapper {
    margin-top: 1rem;
}

@include media-breakpoint-down(md) {
    .category-item.p-2 {
        padding: 0.1rem !important;

        .btn {
            padding: 0.2rem 0.4rem !important;
        }
    }
}
